import React from 'react';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { trims } from 'ucode-utils';
const { setNavBar, loadPermissionsSelection, loadPermissions } = iceStarkData.store.get('commonAction')?.ui;
const { createRole, editRole, getRoleData, getRoleScope } = iceStarkData.store.get('commonAction')?.role;
import { reduxForm } from 'redux-form';
import { Button, Input, Textarea, FormGroupList, FormGroup, StaticFormGroup } from 'tyb';
import { NewSelect } from 'ucode-components';
import { FormGroupField } from 'tyb';
import './index.scss';
import { Tree } from 'antd';
const { TreeNode } = Tree;

const blankReg = /^\s+$/;

@connect(
    (state) => {
        return {
            openIds: state.users.openIds,
            permissionsSelection: state.ui.permissionsSelection,
            roleScope: state.role.roleScope,
        }
    },
    {
        loadPermissionsSelection,
        loadPermissions,
        createRole: createRole.REQUEST,
        editRole: editRole.REQUEST,
        getRoleData: getRoleData.REQUEST,
        getRoleScope: getRoleScope.REQUEST
    }
)


class RolePermissionCreate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            roleTypeList: [
                { value: 'ENTERPRISE', text: '企业类型' },
                { value: 'SYSTEM', text: '系统类型' },
                { value: 'ISV', text: 'ISV类型' },
            ],
            checkedKeys: [],
        }
    }

    componentDidMount() {
        const { openIds, location, loadPermissionsSelection, getRoleData, getRoleScope, initialize } = this.props;
        const parsed = queryString.parse(location.search);
        loadPermissionsSelection(parsed.roleType ? parsed.roleType : 'ENTERPRISE');
        if (parsed.id) {
            this.setState({ isModify: true, roleType: parsed.roleType });
            if (openIds.userType == 'SYSTEM' && parsed.roleType == 'ENTERPRISE') {
                getRoleScope({ id: parsed.id });
            }
            getRoleData({
                id: parsed.id, callback: (res) => {
                    const { permissionIds = [] } = res;
                    this.setState({ checkedKeys: permissionIds });
                    initialize({ ...res });
                }
            })
        }
    }

    validateRoleName = (value) => {
        if (!value || blankReg.test(value)) {
            return '请输入角色名称';
        } else if (value.trim().length > 30) {
            return '长度不能大于30个字符';
        } else {
            return null
        }
    }

    validateRemark = (value) => {
        return value && value.trim().length > 100 ? '备注长度不能超过100个字符' : undefined;
    }

    validateSelectedType = (value) => {
        return value && value !== '请选择角色类型' ? undefined : '请选择角色类型';
    }

    handleSubmit = (data) => {
        const { createRole, editRole, history } = this.props;
        const { checkedKeys } = this.state;
        if (!checkedKeys.length) return;
        data.permissionIds = checkedKeys;
        data.id ? editRole({ data: trims(data), history }) : createRole({ data: trims(data), history });
    }

    goBack = () => {
        this.props.history.goBack();
    }

    onExpand = (expandedKeys, info) => {
        this.setState({ expandedKeys });
    }

    onCheck = (checkedKeys, info) => {
        const { permissionsSelection: list = [] } = this.props;
        const { checked, node: { props: { eventKey, children, dataRef: { url, parentId, permission } } } } = info;
        // 点击是否是最后一层  
        if (permission.indexOf('*') == -1 && permission != 1) {
            let arrJson = new Array();
            this._getOtherLevelNode(list, parentId, arrJson);
            const otherLevelNode = arrJson[0].children.map(item => item.permission).filter(val => val != eventKey); // 兄弟节点
            const parentPermission = parentId.substr(0, parentId.length - 1);
            // 点击的是否是view按钮
            if (url && url == 'view') {
                if (checked) {
                    // Array.prototype.push.apply(checkedKeys, otherLevelNode); // 把其它兄弟也勾上
                } else {
                    checkedKeys = checkedKeys.filter(item => item.indexOf(parentPermission) != 0)
                }
            } else {
                let viewPermission = `${parentPermission}view`;
                if (checked && otherLevelNode.some(val => val.includes('.view'))) {
                    if (checkedKeys.indexOf(viewPermission) == -1) {
                        checkedKeys.push(viewPermission);
                    }
                } else {
                    // const otherLevelNodeFilter = otherLevelNode.filter(item => item != viewPermission && item != permission); // 去掉查看按钮
                    // let i = 0;
                    // otherLevelNodeFilter.map(val => {
                    //     checkedKeys.map(cVal => {
                    //         if (cVal == val) {
                    //             i++;
                    //         }
                    //     })
                    // })
                    // if (i == 0) {
                    //     checkedKeys = checkedKeys.filter(item => item != viewPermission);
                    // }
                }
            }
        }
        this.setState({ checkedKeys })
    }

    _getOtherLevelNode = (param, parentId, arrJson) => {
        param.forEach(val => {
            if (val.permission == parentId) {
                arrJson.push(val);
            }
            val.children && val.children.length > 0 ? this._getOtherLevelNode(val.children, parentId, arrJson) : '';
        })
    }

    renderTreeNodes = (data, index) => data.map((item) => {
        // let key = `${index}-${item.id}`;
        if (item.children) {
            return (
                <TreeNode title={item.title} key={item.permission} dataRef={item} className={!item.parentId ? 'tree-item' : ''}>
                    {this.renderTreeNodes(item.children)}
                </TreeNode>
            );
        }
        return <TreeNode {...item} key={item.permission} dataRef={item} className={item.url ? `tree-item-url` : `tree-item`} />;
    })

    render() {
        const { handleSubmit, openIds, permissionsSelection, roleScope, loadPermissionsSelection } = this.props;
        const { checkedKeys, expandedKeys = ['.*'], roleTypeList, roleType = '', isModify = false, canSubmit = true } = this.state;
        const roleTypeItem = roleTypeList.filter(item => item.value == roleType)[0];
        return (
            <div className="role-form-bg">
                <FormGroupList>
                    <FormGroupField
                        name={`name`}
                        label="角色名称"
                        required
                        component={Input}
                        validate={this.validateRoleName}
                    />
                    {openIds.userType == 'SYSTEM' && !isModify && <FormGroupField
                        name={`roleType`}
                        label="角色类型"
                        placeholder="请选择角色类型"
                        required
                        component={NewSelect}
                        style={{width:330}}
                        list={roleTypeList}
                        validate={[this.validateSelectedType]}
                        disabled={isModify}
                        onChange={(e) => {
                            this.setState({ checkedKeys: [] })
                            loadPermissionsSelection(e);
                        }}
                    />}
                    {openIds.userType == 'SYSTEM' && isModify && <FormGroup
                        label="角色类型"
                        required
                        control={Input}
                        disabled
                        value={roleTypeItem && roleTypeItem.text || ''}
                    />}
                    <StaticFormGroup
                        label="配置权限"
                        required
                    >
                        <div className="role-tree-title">请选择操作权限</div>
                        <div className="role-tree antd-tree-style">
                            <div>
                                {permissionsSelection.length != 0 && <Tree
                                    // showLine
                                    checkable
                                    expandedKeys={expandedKeys}
                                    checkedKeys={checkedKeys}
                                    onCheck={this.onCheck}
                                    onExpand={this.onExpand}
                                >
                                    {this.renderTreeNodes(permissionsSelection, 0)}
                                </Tree>}
                            </div>
                            {openIds.userType == 'SYSTEM' && roleType == 'ENTERPRISE' && <div style={{ paddingLeft: 15 }}>
                                <p>正在使用的企业</p>
                                {roleScope.map((val, index) => {
                                    return <div key={index}>{val.name}</div>
                                })}
                            </div>}
                        </div>
                        {!canSubmit && !checkedKeys.length && <span style={{ color: '#E1504A' }}>操作权限不能为空</span>}
                    </StaticFormGroup>
                    <FormGroupField
                        name={`remark`}
                        label="备注"
                        component={Textarea}
                        validate={this.validateRemark}
                    />
                </FormGroupList>
                <div className="form-edit-border">
                    <Button
                        onClick={() => {
                            this.setState({ canSubmit: false });
                            handleSubmit(this.handleSubmit)();
                        }}
                    >{isModify ? '确认' : '新增'}</Button>
                    <Button
                        className="tc-15-btn weak"
                        onClick={this.goBack}
                    >取消</Button>
                </div>
            </div>
        )
    }

}

const RoleEditForm = reduxForm({
    form: 'roleForm',
    initialValues: { 'name': '', roleType: 'ENTERPRISE' }
})(RolePermissionCreate);

export default RoleEditForm;